<template>
  <div class="commonSelection">
    <!--    <div class="selected">-->
    <!--      <div class="title">{{ $t('be315ca') }}：</div>-->
    <!--      <div class="content">-->
    <!--        <template>-->
    <!--          <div class="clear item" @click="clearSelect">-->
    <!--            {{ $t('df61523') }}-->
    <!--          </div>-->
    <!--          <div v-for="(content, key) of selectedObj" v-if="content" :key="key" class="item">-->
    <!--            {{ content.name }}-->
    <!--            <div class="closeSel" @click="clearItem(key)"></div>-->
    <!--          </div>-->
    <!--        </template>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="conditionSelection">
      <div
        v-for="(item, key) in category"
        :key="item.id"
        class="condition"
      >
        <div class="title">{{ $t(item.title) }}:</div>
        <div class="content">
          <div
            v-for="content in item.data"
            :key="content.id"
            class="item"
            :class="{
              active: selectedObj[`category${key + 1}`] && selectedObj[`category${key + 1}`].id === content.id
            }"
            @click="selectItem(content)"
          >
            <span>{{ content.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/contract/service"
export default {
  name: 'ConditionSelection',
  data() {
    return {
      list: [],
      categoryList: [],
      categoryTwoData: [],
      selectedObj: {
        category1: '',
        category2: '',
      }
    }
  },
  computed: {
    category() {
      return [
        {
          title: 'e2e7291',
          type: 'firstLevel',
          data: this.list,
        },
        {
          title: 'fd4b703',
          type: 'secondLevel',
          data: this.categoryTwoData
        }
      ]
    }
  },
  created() {
    this.getData()
  },
  methods: {
    clearSelect() {
      this.selectedObj = {
        category1: '',
        category2: '',
      }
      this.search()
    },
    getData() {
      API.getContractOptions().then(res => {
        console.log('res.content', res.content)
        this.list = res.content
      })
    },
    clearItem(key) {
      this.selectedObj[key] = ''
      this.search()
    },
    selectItem(content) {
      if (content.level === 1) {
        this.categoryTwoData = content.children
        this.selectedObj.category2 = ''
      }
      this.selectedObj[`category${content.level}`] = content
      this.search()
    },
    search() {
      const { category1, category2 } = this.selectedObj
      const obj = {
        category1: category1 ? category1.id : '',
        category2: category2 ? category2.id : '',
      }
      this.$emit('search', obj)
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';

.commonSelection {
  display: flex;
  flex-direction: column;
  width: @uni-width;
  margin: 40px 0;
  background: rgba(0, 164, 255, 0.04);
  border-radius: 4px;
  color: #333333;

  .title {
    display: flex;
    justify-content: flex-end;
    width: 70px;
  }
  .item {
    margin-left: 20px;
    cursor: pointer;
  }

  .selected {
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #eeeeee;
    font-size: 13px;
    .content {
      display: flex;
      align-items: center;
      min-height: 25px;
      font-weight: bold;
      color: #42a5f5;
      .item {
        position: relative;
        padding: 5px 10px;
        border-radius: 2px;
        border: 1px solid #42a5f5;
        .closeSel {
          position: absolute;
          top: -6px;
          right: -6px;
          width: 12px;
          height: 12px;
          background: url('~@/assets/service/closeSel.png');
          background-size: 12px 12px;
          cursor: pointer;
        }
      }
      .clear {
        padding-left: 0;
        color: #cccccc;
        border: none;
        cursor: pointer;
        &::after {
          display: none;
        }
      }
    }
  }

  .conditionSelection {
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 20px;
    font-size: 13px;
    .condition {
      display: flex;
      margin-bottom: 10px;
    }
    .title {
      white-space: nowrap;
      color: #333333;
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      max-width: 90%;
      .item {
        margin-bottom: 10px;
        color: #666666;
      }
      .active {
        color: @uni-text-color-select;
      }
    }
  }
}
.popoverContainer {
  .childContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0px 30px 20px;
    font-size: 14px;
    color: #999999;
    line-height: 19px;
    .childItem {
      margin: 20px 20px 0 0;
      cursor: pointer;
      &:hover {
        color: @uni-text-color-select;
      }
    }
    .active {
      color: @uni-text-color-select;
    }
  }
}
</style>
