<template>
  <div v-loading="loading" class="desk">
    <ConditionSelection
      @search="search"
    />
    <article v-if="contractList.length" class="article">
      <div v-for="(item, key) in contractList" :key="key" class="item" @click="jumpDetail(item.id)">
        <span class="item__title">{{ item.title }}</span>
        <span class="item__date">{{ item.releaseTime }}</span>
      </div>
      <Pagination class="pagination" :page-info="pageInfo" @changePage="changePage" />
    </article>
    <NoData v-else type="content" size="medium" class="no-data" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import ConditionSelection from './components/ConditionSelect'
import API from "@/api/contract/service"
import NoData from '@/components/NoData'
export default {
  name: 'Contract',
  components: {
    ConditionSelection,
    Pagination,
    NoData,
  },
  data() {
    return {
      loading: false,
      contractList: [],
      pageInfo: {
        currentPage: 1,
        size: 15,
        total: 0,
      },
      category1: '',
      category2: '',
    }
  },
  created() {
    this.getData()
  },
  methods: {
    jumpDetail(id) {
      this.$router.push({
        path: `/service/contract/detail?id=${id}&page=${this.pageInfo.currentPage}&size=${this.pageInfo.size}`
      })
    },
    search(data) {
      console.log('data', data)
      const { category1, category2 } = data
      this.category1 = category1
      this.category2 = category2
      this.getData()
    },
    isValidObj(obj) {
      return obj && !!Object.keys(obj).length
    },
    getData() {
      this.loading = true
      const { pageInfo: { size, currentPage: page }, category1, category2 } = this
      API.get({
        category1,
        category2,
        size,
        page
      }).then(res => {
        this.contractList = res.content
        this.loading = false
        this.pageInfo.total = res.page.total
      })
    },
    changePage(page) {
      this.pageInfo.currentPage = page
      this.getData()
    }
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.desk {
  width: @uni-width;
  margin: 0 auto;
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .complement {
      width: 285px;
      height: 368px;
    }
  }
  .pagination {
    height: 82px;
    align-items: center;
  }
}
.article {
  background-color: #fff;
  color: #666666FF;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px rgba(2, 24, 36, 0.04);
  margin-bottom: 20px;
  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 76px;
    align-items: center;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.09);
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      color: #00A4FFFF;
    }
    &__title {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      font-weight: 500;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 540px;
    }
    &__date {
      margin-left: 20px;
      text-align: right;
      font-size: 14px;
    }
  }
}
  .no-data {
    margin-bottom: 50px;
  }
</style>
